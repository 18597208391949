import React from 'react'
import styledComponents from 'styled-components'

export const AuthModal = ({redirectToSignup,redirectToLogin}) => {
  return (
    <AuthContainer>
        <div className="col-5 col-md-4 col-lg-3 col-sm-4 auth-div">

        <div className="col-12 col-md-12 col-lg-12 col-sm-12 login-sm text-center" onClick={()=>redirectToSignup()}>
             <label className='auth-name px-2 my-1'>Sign up</label>
         </div>   
         <div className="col-12 col-md-12 col-lg-12 col-sm-12 login-sm text-center" onClick={()=>redirectToLogin()}>
            <label className='auth-name px-2 my-1'>Log in</label>
         </div>   

        </div>
    </AuthContainer>
  )
}

const AuthContainer=styledComponents.div`

position:relative;

.auth-div{
    position:absolute;
    background: var(--lightDark2);
    // left:62%;
    // right:0;
    bottom:0;
    z-index:1111;
    border-radius:0.2rem;

}

@media screen and (min-width: 1025px) {
  .auth-div {
      left: 70%;
      right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .auth-div {
      left: 70%;
      right: 0;
  }
}

@media screen and (max-width: 980px) {
  .auth-div {
      left: 62%;
      right: 0;
  }
}

@media screen and (max-width: 660px) {
  .auth-div {
      left: 59%;
      right: 0;
  }
}

@media screen and (max-width: 390px) {
  .auth-div {
      left: 61%;
      right: 0;
  }
}


.login-sm{
  cursor:pointer;

  &:hover{
      background: var(--lightOrange);
  }
}

.auth-name{
    color: var(--mainWhite);
    font-family: 'Karla', sans-serif;
    font-size:0.9rem;
    cursor:pointer;
}

@media (min-width: 992px) {
  .auth-div {
      top: -3.8rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .auth-div {
      top: -3.8rem;
  }
}

@media (max-width: 767px) {
  .auth-div {
      top: -3.7rem;
  }
}

`;
